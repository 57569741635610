<style lang="scss" scoped>
.fdd {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .main {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 12px;
  }
}
</style>

<template>
  <div class="fdd" style="height: 100%; overflow-y: auto">
    <!-- 过滤区域 -->
    <box-item class="box">
      <div class="filter-container">
        <div class="filter-input-container">
          <!-- 区域 -->
          <div class="input-item">
            <div class="input-item-title">空间</div>
            <el-select v-model="v1">
              <el-option
                v-for="item of spaces"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>

        <el-button type="primary" @click="loadData">提交</el-button>
      </div>
    </box-item>

    <div class="main">
      <box-item
        style="margin-bottom:12px"
        v-for="(item, index) of tableData"
        :key="index"
      >
        <EqList :listData="item"></EqList>
      </box-item>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import EqList from "../components/eq-list.vue";

const v1 = ref(null);
const tableData = ref([]);
const loadData = () => {
  tableData.value = [
    {
      img: require("@/assets/eq/1.png"),
      name: "高压制冷机CH-ZL-01",
      type: "空调水系统/冷水机组",
      list: [
        "Brand: York",
        "Manufactured: 2011",
        "Ton: 800 Ton",
        "Model#: YKK8K3H9-CUG",
        "Serial#: SNXM-143960",
        "Type: Water-Cooled",
        "Compressor Type: Centrifugal",
        "Power: 460 Volts / 60 Hz / 3 Ph",
        "Refrigerant: R-134a",
      ],
      params: [
        "累计效率:6.7 kW/kW",
        "总瞬时功率:26.2 kW",
        "总功率因数:0.86",
        "有功功率:24 kW",
        "冷冻水出水温度:5 degree C",
        "冷冻水回水温度:25.1 degree C",
        "通信状态: 在线",
        "设备状态: 运行",
      ],
    },
    {
      img: require("@/assets/eq/2.png"),
      name: "高压制冷机CH-ZL-02",
      type: "空调水系统/冷水机组",
      list: [
        "Brand: York",
        "Manufactured: 2011",
        "Ton: 800 Ton",
        "Model#: YKK8K3H9-CUG",
        "Serial#: SNXM-143960",
        "Type: Water-Cooled",
        "Compressor Type: Centrifugal",
        "Power: 460 Volts / 60 Hz / 3 Ph",
        "Refrigerant: R-134a",
      ],
      params: [
        "累计效率:6.7 kW/kW",
        "总瞬时功率:26.2 kW",
        "总功率因数:0.86",
        "有功功率:24 kW",
        "冷冻水出水温度:5 degree C",
        "冷冻水回水温度:25.1 degree C",
        "通信状态: 在线",
        "设备状态: 运行",
      ],
    },
    {
      img: require("@/assets/eq/3.png"),
      name: "高压制冷机CH-ZL-03",
      type: "空调水系统/冷水机组",
      list: [
        "Brand: York",
        "Manufactured: 2011",
        "Ton: 800 Ton",
        "Model#: YKK8K3H9-CUG",
        "Serial#: SNXM-143960",
        "Type: Water-Cooled",
        "Compressor Type: Centrifugal",
        "Power: 460 Volts / 60 Hz / 3 Ph",
        "Refrigerant: R-134a",
      ],
      params: [
        "累计效率:6.7 kW/kW",
        "总瞬时功率:26.2 kW",
        "总功率因数:0.86",
        "有功功率:24 kW",
        "冷冻水出水温度:5 degree C",
        "冷冻水回水温度:25.1 degree C",
        "通信状态: 在线",
        "设备状态: 运行",
      ],
    },
    {
      img: require("@/assets/eq/4.png"),
      name: "高压制冷机CH-ZL-04",
      type: "空调水系统/冷水机组",
      list: [
        "Brand: York",
        "Manufactured: 2011",
        "Ton: 800 Ton",
        "Model#: YKK8K3H9-CUG",
        "Serial#: SNXM-143960",
        "Type: Water-Cooled",
        "Compressor Type: Centrifugal",
        "Power: 460 Volts / 60 Hz / 3 Ph",
        "Refrigerant: R-134a",
      ],
      params: [
        "累计效率:6.7 kW/kW",
        "总瞬时功率:26.2 kW",
        "总功率因数:0.86",
        "有功功率:24 kW",
        "冷冻水出水温度:5 degree C",
        "冷冻水回水温度:25.1 degree C",
        "通信状态: 在线",
        "设备状态: 运行",
      ],
    },
  ];
};

loadData();
</script>
