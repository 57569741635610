<style lang="scss" scoped>
.list-item {
  width: 100%;
  height: 350px;
  display: flex;
  .left-img-container {
    width: 420px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .center-text-container {
    margin-left: 32px;
    flex: 1;
    height: 100%;
    .text-title {
      font-size: 16px;
    }
    .sub-title {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
    }
    .text-list {
      margin-top: 16px;
      .text-list-item {
        font-size: 14px;
      }
    }
  }
  .right-params-container {
    width: 350px;
    height: 100%;
    .params-title {
      font-size: 24px;
      color: rgb(245, 114, 7);
    }
    .params-item {
      font-size: 14px;
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
    .button-item {
      width: 100%;
      height: 35px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      &:not(:first-child) {
        margin-top: 6px;
      }
    }
    .error-btn {
      border: 1px solid rgba(255, 0, 0, 0.842);
      color: rgba(255, 0, 0, 0.842);
    }
    .normal-btn {
      background: rgba(19, 42, 248, 0.801);
      color: rgba($color: #ffffff, $alpha: 1);
      border: 1px solid rgba(19, 42, 248, 0.801);
    }
  }
}
</style>

<template>
  <div class="list-item">
    <div class="left-img-container">
      <img :src="listData.img" alt="" />
    </div>
    <div class="center-text-container">
      <div class="text-title">{{ listData.name }}</div>
      <div class="sub-title">{{ listData.type }}</div>
      <div class="text-list">
        <div class="text-list-item" v-for="list in listData.list">
          · {{ list }}
        </div>
      </div>
    </div>
    <div class="right-params-container">
      <div class="params-title">瞬时效率 5.6 (kWh/kWh)</div>
      <div class="params-item" v-for="item in listData.params">{{ item }}</div>
      <div class="button-item error-btn">故障报警</div>
      <div class="button-item normal-btn">运行命令</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: Object,
  },
};
</script>
